.requests{
    width:1440px;
    margin:auto;
    margin-top: 100px;

    table{
        border:1px solid #333;
        width:100%;
        background-color:#111;
        margin-top:50px;
        tr:nth-child(even) {
            background-color:#222;
        }
        tr{
            border-bottom: 1px solid #333;
            td{
                padding:5px;
            }
        }

    }
}