html, body, #root, .full-height
{
    height:100%;
}

html, body, ul, li, div
{
    font-family: $fonts-face;
    color: $white;
    padding:0;
    margin:0;
}

body{
    background-color:#333;
    background-image: url('../assets/background-games.png');
    background-size:contain;
    background-position: top center;
    background-attachment: fixed;
}

ul{
    list-style: none;
}

// standard fonts
h1 {
    font-size: 60px;
    color: $white;
    line-height: 70px;
}

h2 {
    font-size: 20px;
    color: $white;
    line-height: 24px;
}
h3 {
    font-size: 14px;
    color: $white;
    line-height: 18px;
}

p {
    font-size: 12px;
    color: $white;
    line-height: 16px;
}

