.team {
    
}

.teamMembers{
    width:100%;
    display:flex;
    flex-flow: column;
    justify-content:center;
    align-items:flex-start;
}

@media(min-width: 768px){
    .teamMembers{
        flex-flow:row;
    }
}