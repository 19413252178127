.footer {
    background-color: $black;
    
    nav {
        background-color:#000;
    }

    a{
        color:$white !important;
        &:hover{
            color:$primary-light !important;
        }
    }

    .navbar-nav{
        display:flex;
        flex-direction: row;
        a {
            margin-right:20px;
            line-height: 28px;
        }
        .social {
            img{
                background-color:$white;
                border-radius:14px;
                padding: 2px;
                height: 28px;
                width: 28px;
                &:hover{
                    background-color:$primary-light;
                }
            }

        }

        .social-links{
            display:flex;
            flex-direction: row;
        }
    }
}

@media (max-width: 768px) {
    .footer{
        .navbar-nav{
            display:block;
            text-align:center;
            flex-direction: row;
            width:100%;
            a {
                margin-right:0;
            }
            .social {
                img{
                    margin:0 10px;
                }

            }
            .social-links{
                width:fit-content;
                margin:auto;
            }
        }
        .navbar-text{
            width:100%;
            text-align:center;
        }
        
    }
}