.imageTextPanel {
  display: flex;
  justify-content: center;
  align-items: center;
}

.right {
  flex-flow: column-reverse;
}

.left {
  flex-flow: column-reverse;
}

.imageTextPanel div span {
  color: white;
  font-size: 12px;
  max-width: 100%;
  padding: 0;
}

.imgDiv {
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.textDiv {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-flow: column;
  padding: 0 5px;
}
.textDiv h3 {
  text-align: left;
}

.detailsPanel {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-flow: column;
}

.titleContainer {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: left;
  align-items: flex-start;
  margin-bottom: 20px;
}

.title {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
}

.underline {
  max-width: 163px;
}

.imageTextPanel div {
  display: flex;
}

.imageTextPanel div img {
  width: 100%;
  height: auto;
}

.playStoreButtonHolder {
  width: 100%;
  height: 40px;
}

.padding0 {
  padding: 0 !important;
}

.playStoreButtonContainer {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 0;
}

.hide {
  display: none;
}

@media (min-width: 1024px) {
  .right {
    flex-flow: row;
  }

  .left {
    flex-flow: row-reverse;
  }

  .imgDiv {
    width: 100%;
  }

  .textDiv {
    width: 100%;
    
    padding: 40px;
  }
  .imageTextPanel div span {
    padding: 0 40px;
  }

  .playStoreButtonHolder {
    width: 150px;
  }
  .playStoreButtonContainer {
    padding: 0 40px;
  }
}
