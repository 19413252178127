.cpndj {
}

.cpndj .introContainer {
  background-image: url("../../assets/cpndj_background.png");
}

.imageTextPanel {
  margin-top: 50px;
}

.margin20 {
  margin-top: 20px;
}

.cpndj .stayConnectedPanel {
  background-color: #1b2415;
}

.cpndj .cardButton {
  background-color: #058c2b !important;
}

.cpndj .cardButton:hover {
  background-color: #139036 !important;
}

.cpndj .cardButton:active {
  background-color: #2f924b !important;
}

.cpndj .inputBox input {
  border: 1px solid #058c2b;
  background-color: #544e4a;
}
.cpndj .inputBox button {
  border: 1px solid #058c2b;
  background-color: #058c2b;
}

.cpndj .inputBox button:hover {
  background-color: #2f924b !important;
}

.cpndj .inputBox button:active {
  background-color: #2f924b !important;
}
