.tlp {
  font-family: 'Open Sans', sans-serif;
}

.tlp .introContainer {
  background-image: url("../../assets/wallpaper_1.jpg");
  height: fit-content;
  text-align: left;
  background-position: top left !important;
}


.tlp .introContainer .content{
  display: flex;
  flex-flow:column;
  padding-top:50px;
  padding-bottom:50px;
  width: 100%;
  gap:150px;
  min-height: 100vh;
}

.tlp .introContainer .content .registerContainer{
  width:100%;
  padding: 0 5px;
  display: flex;
  flex-flow: column;
  align-items:center;
  justify-content: left;
}

.tlp .introContainer .content .teaserContainer{
  width:100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap:50px;
  justify-content:center;
}

.tlp .introContainer .content .teaserContainer .teaserPlayer{
  width:350px;
  height: 196.875px;
}

.tlp .introContainer .content .registerContainer .registerTitle{
  text-transform: uppercase;
  font-weight: 900;
  font-size:33px;
  max-width: 500px;
  line-height: 90px;
  text-align: center;
}

.tlp .introContainer .content .registerContainer .registerDescription{
  max-width: 500px;
  line-height: 90px;
}
.tlp .introContainer .content .registerContainer .registerDescription p{
  font-size:14px;
  line-height:18px;
}

.tlp .introContainer .content .registerContainer .registerInput{
  width:100%;
  max-width: 550px;
  height: 50px;
}

.tlp .introContainer .content .registerContainer .registerInput .signUpImage{
  width:302px;
  height:auto;
  position:relative;
  top:-155px;
  right:-495px;
  display: none;
}

.tlp .introContainer .content .registerContainer .registerInput .signUpImageSmall{
  width:150px;
  height:auto;
  position:relative;
  top:-15px;
  right:-220px;
  display: inline-block;
}

.tlp .introContainer .content .registerContainer .registerInput .inputBox input{
  border: 1px solid #2B98BB !important;
  background-color: #fff!important;
  color:#000;
}

.tlp .introContainer .content .registerContainer .registerInput .inputBox input::placeholder{
  font-weight: 700;
  font-size:17px;
  color:#a1a1a1;
}

.tlp .introContainer .content .registerContainer .registerInput .inputBox button {
  color: white;
  position: relative;
  border: 1px solid #2B98BB !important;
  background-color: #2B98BB !important;
  font-weight: 900 !important;
}


.tlp .introContainer .content .registerContainer .registerInput .inputBox button:hover {
  background-color: #60a3b9 !important;
}

.tlp .introContainer .content .registerContainer .registerInput .inputBox button:active {
  background-color: #83aebd !important;
}

@media(min-width:1366px){
  .tlp .introContainer .content{
    padding-top:200px;
    display: flex;
    flex-flow:row;
    justify-content: space-between;
    width: 100%;
  }

  .tlp .introContainer .content .registerContainer{
    padding: 0 0 100px 100px;
    display: flex;
    flex-flow: column;
  }

  .tlp .introContainer .content .registerContainer .registerTitle{
    font-size:66px;
    max-width: 550px;
    text-align: left;
  }
  
  .tlp .introContainer .content .registerContainer .registerDescription{
    max-width: 550px;
  }

  .tlp .introContainer .content .registerContainer .registerDescription p{
    font-size:18px;
    line-height:24px;
  }

  .tlp .introContainer .content .teaserContainer .teaserPlayer{
    width:480px;
    height:270px;
  }

  .tlp .introContainer .content .registerContainer .registerInput{
    width:100%;
    max-width: 550px;
    height: 50px;
  }

  .tlp .introContainer .content .registerContainer .registerInput .signUpImage{
    display: inline-block;
  }
  
  .tlp .introContainer .content .registerContainer .registerInput .signUpImageSmall{
    display:none;
  }
}