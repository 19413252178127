.vajra {
}

.vajra .container {
}

.infoText {
  width: 100%;
  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  max-width: 672px;
  margin-top: 50px;
  margin: 50px auto 0 auto;
}

.infoText p {
  font-size: 12px;
  line-height: 16px;
}

.vajra .introContainer {
  background-image: url("../../assets/bannerImage_HD_1.png");
  height: 310px;
}

.introTitle {
  display: flex;
  flex-flow: column;
  justify-content: left;
  align-items: flex-start;
}

.titleText {
  font-size: 12px;
  margin-top:20px;
  margin-bottom: 20px;
  max-width:174px;
  text-align: left;
  line-height: 20px;
}

.titleImage{
  max-width: 109px;
}

.subtitleText {
  font-size: 12px;
}

@media (min-width: 768px) {
  .vajra .introContainer {
    height: 1080px;
  }

  .infoText p {
    width:70%;
  }

  .titleText {
    font-size: 20px;
    line-height: 30px;
    max-width:360px;
    margin-top:40px;
    margin-bottom: 40px;
  }

  .subtitleText {
    font-size: 20px;
  }

  .titleImage{
    max-width: 360px;
  }
}
