.betaTestingContainer {
  width: 100%;
  padding:  0;
  margin-top: 50px;
}

.emailHolder {
  width: 100%;
  height: auto;
  background-color: #281e17;
  display: flex;
  flex-flow: column-reverse;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding-bottom: 20px;
}

.title {
  display: inherit;
  margin-top: 20px;
}

.displayImage {
  width: 320px;
  margin: 30px 0;
}

.detailsContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.closedBetaTitle {
  display: none;
  color: white;
  font-size: 20px;
  text-transform: uppercase;
  margin-top: 40px;
  margin-bottom:30px;
}

.closedBetaDescription {
  color: white;
  font-size: 14px;
  width: 320px;
  margin-bottom: 30px;
}

.submitButton {
  width: 320px;
  height: 40px;
}

@media (min-width: 1200px) {
  .emailHolder {
    flex-flow: row;
    height: 268px;
    gap: 10%;
    padding-bottom: 0;
  }

  .closedBetaTitle {
    display: block;
  }

  .displayImage {
    width: 618.35px;
    height: 348px;
  }

  .closedBetaDescription {
    width: 416px;
    font-size: 20px;
  }

  .detailsContainer {
    width: 426px;
    justify-content: left;
    align-items: flex-start;
  }

  .title {
    display: none;
  }

  .submitButton {
    width: 360px;
  }

  .betaTestingContainer {
    padding: 40px 0;
    margin-top: 70px;
  }
}
