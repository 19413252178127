@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap');

// fonts 
$fonts-face: 'Open Sans', sans-serif;

//colors-generic
$white: #fff;
$black: #000;

//colors-theme
$base-color: #333333;
$primary-dark: #e49200; 
$primary-light: #ffcd20; // #ffaf00;
$text: #333;
$background: #ffa087;
$button-background:$background;

//spacing
$base-space:10px;
$button-padding: 5px 10px;

// nav
$nav-background-color: #000;
$nav-text: #fff;
$nav-height:60px;