@import '../style';

.privacy-policy{
    
    padding-top:100px;
    p, li{
        font-size: 14px;
        line-height:20px;
        margin-bottom:20px;
        color: #f0f0f0;
    }
    h1{
        margin-bottom:30px;
        font-size:40px;
        font-weight: 700;
    }
    h2{
        margin-top:50px;
        margin-bottom:20px;
        font-weight: 700;
    }
    h3{
        font-size:16px;
        margin-bottom:20px;
        font-weight: 700;
    }
    ul{
        list-style-type: disc;
        padding-left:40px;
    }
}