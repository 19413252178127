.introContainer {
  background-repeat: no-repeat;
  background-position: top center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 274px;
}

.introContainer .container{
  width: 90%;
  max-width: 90%;
}

.introContainer h2 {
  width: 100%;
  margin-bottom: 0px;
  font-size: 12px;
  max-width: 90%;
}

.introContainer h1 {
  display: inline-block;
  width: 100%;
  font-size: 30px;
  line-height: 40px;
  margin-top: 40px;
}

@media (min-width: 768px) {
  .introContainer {
    height: 557px;
  }

  .introContainer h1 {
    font-size: 66px;
    line-height: 70px;
    margin-top: 0;
    font-weight: 900;
  }

  .introContainer h2 {
    font-size: 16px;
    width: 50%;
    margin-top: 15px;
    max-width: 576px;
  }
}
