.about {
  width: 100%;
}

.about .introContainer {
  background-image: url(../../assets/Group88.png);
}

.about .stayConnectedPanel {
  background-color: #281E17;
}
