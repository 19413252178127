.teamMember {
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: left;
  align-items: flex-start;
  gap: 30px;
  margin-top:30px;
  margin-bottom: 30px;
}

.details {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: left;
  align-items: flex-start;
  color: white;
}

.image {
  width: 66px;
  height: auto;
  margin-top: 20px;
}

.imageContainer {
  width: 30%;
  display: flex;
  justify-content: flex-end;
}

.title {
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 20px;
  text-align: left;
}

.description {
  padding: 0 20px 0 0;
}

@media (min-width: 768px) {
  .teamMember {
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }

  .details {
    justify-content: center;
    align-items: center;
  }

  .image {
    width: 150px;
    margin:0;
  }

  .title {
    text-transform: uppercase;
    font-size: 20px;
    text-align: center;
  }

  .description {
    text-align: center;
    padding: 0 40px;
  }

  .imageContainer {
    width:100%;
    justify-content:center;
  }
}
