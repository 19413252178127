.gameCards{
    width:100%;
    display:flex;
    flex-flow:column;
    justify-content: center;
    align-items: center;
    gap:40px;
    margin-top: 50px;
}

@media (min-width: 1200px) {
    .gameCards{
        flex-flow:row;
        gap:40px;
    }
  }