.cardButton {
  background-color: #e49200;
  height: 100%;
  width: 100%;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border: none;
  color: white;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.1s;
  margin:auto;
  overflow: hidden;
}

.cardButton:hover {
  background-color:#EAA834;
}

.cardButton:active {
  background-color:#E8B760;
}

@media (min-width: 768px) {
  .cardButton{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
