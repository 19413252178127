nav{
    &.bg-custom-dark{
        background-color: rgba($color: #000000, $alpha: 0.8);
        // background-image: linear-gradient( rgba(0,0,0,0.5),  rgba(0,0,0,0));
        color: $white;
        .navbar-brand{
            background-image:url('../assets/logo-dark.png');
            width:118px;
            height:50px;
            background-size: auto 100%;
            background-position: center middle;
            background-repeat: no-repeat;
            
        }
        #basic-navbar-nav{
            .navbar-nav{
                a{
                    color:#fff;
                    &.active, &:hover{
                        color:$primary-light;
                    }
                }
                .dropdown{
                    &.show{
                        .dropdown-toggle {
                            color:$primary-light;
                        }
                    }
                    .dropdown-menu{
                        background-color:rgba($color: #000000, $alpha: 0.8);
                        top:55px;
                        
                        a{
                            &:hover{
                                background-color:rgba($color: #000000, $alpha: 0.0);
                                color:$primary-light;
                            }
                        }
                    }
                }
            }
            .social{
                position:absolute;
                right:10px;
                top:0;
                a{
                    height:70px;
                    width:30px;
                    background-image: url('../assets/banner_white.png');
                    color:$black;
                    margin: 0 5px;
                    line-height:45px;
                    padding-right:3px;
                    padding-left:3px;

                    &:hover{
                        color:$black;
                        background-image: url('../assets/banner_yellow.png');
                    }
                    img
                    {
                        position:relative;
                        height:24px;
                        width:24px;
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
    nav{
        #basic-navbar-nav{
            .social {
                display: none;
            }
            .navbar-nav{
                .dropdown {
                    .dropdown-menu {
                        background-color: transparent !important;
                    }
                }
            }
        }
        button{
            border:0 !important;
            color:$white !important;
        }
    }
}