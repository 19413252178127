.stayConnectedPanel {
  width: 100%;
  padding: 20px 0;
  margin-top:30px;
}

.detailsPanel {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width:100%;
  margin-top:20px;
  gap:20px;
}

.emailPanel {
    width:100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.emailPanel span{
    color:white;
    font-size:12px;
    width:320px;
    margin-top:10px;
    text-align:left;
}

.emailBox {
    width:320px;
    height:40px;
}

.subText{
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    color:white;
    font-size:14px;
    font-weight: 500;
}

.subText span{
    max-width:320px;
    text-align:left;
}

@media(min-width:768px){
    .detailsPanel {
        flex-flow: row;
        gap:0px;
      }

      .emailBox {
        width:360px;
    }

    .subText span{
        font-size:20px;
        max-width: 100%;
    }

    .emailPanel span{
        width:360px;
    }
}