.gameCard {
  height: 466px;
  width: 320px;
  margin: 10px;
  background-color: #110d0a;
  -moz-box-shadow: 0 0 15px rgba(255, 255, 255, 0.2);
  -webkit-box-shadow: 0 0 15pxrgba (255, 255, 255, 0.2);
  box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.2);
  border-radius: 2px;
}

.infoPanel {
  width: 100%;
  height: 280px;
  padding: 20px 20px 0 20px ;
  display: flex;
  flex-flow: column;
  justify-content: center;
  font-family: "Segoe UI", sans-serif;
}

.title {
  color: white;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
}

.description {
  margin-top: 10px;
  color: white;
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description p {
  margin-top: 5px;
}

.image {
  width: 100%;
  height: 180px;
}

.buttonHolder {
  width: 100%;
  height:40px;
  margin:0 auto;
}

.cardButton {
  width:100%;
  height:40px;
}

@media (min-width: 768px) {
  .gameCard {
    width:400px;
    max-height: 583px;
  }

  .buttonHolder {
    width: 150px;
    margin-top:30px;
  }

  .gameCard {
    height: 583px;
  }

  .infoPanel {
    height:250px;
  }

  .image {
    height: 225px;
  }

  .title {
    font-size:20px;
  }
  
}
