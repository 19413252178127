.inputBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row;
  justify-content: center;
}

.circular-progress {
  color:white;
}

.inputBox input {
  width: 70%;
  height: 100%;
  border: 1px solid #e49200;
  border-radius: 2px 0px 0px 2px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background-color: #544e4a;
  padding: 0 10px;
  font-size: 16px;
  color: white;
}

.inputBox input:focus {
  outline: none;
}

.inputBox input::placeholder {
  font-size: 20px;
  font-weight: 500;
}

.inputBox button {
  width: 30%;
  height: 100%;
  color: white;
  position: relative;
  border: 1px solid #e49200;
  background-color: #e49200;
  border-radius: 0px 2px 2px 0px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  cursor: pointer;
  overflow: hidden;
}


.inputBox button:hover {
  background-color: #dba03b !important;
}

.inputBox button:active {
  background-color: #dba03b !important;
}