.titleContainer {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  text-align: center;
}

.arrow {
  height: 12px;
}

.titleText {
  color: white;
  font-size: 14px;
  text-transform: uppercase;
  margin: 0 5px;
}

@media (min-width: 768px) {
  .arrow {
    height: 30px;
  }
  .titleText {
    font-size: 20px;
    margin: 0 10px;
  }
}
